<template>
  <div>
    <Pane>
      <PaneHeader id="detailsHeader">
        <template #close>
          <!-- this button is for the mobile responsive view of winback page -->
          <button
            class="btn btn-link mr-3 mt--2 p-0 text-dark"
            v-if="showDrawerClose"
            @click="$emit('close')"
          >
            <ChevronLeftIcon size="1.5x"></ChevronLeftIcon>
          </button>
        </template>
        <template #title>Customer Info</template>
      </PaneHeader>

      <div :style="{ height: innerPaneHeight + 'px' }">
        <!-- customer details -->
        <div v-if="selectActiveConversation" class="h-100">
          <CustomerDetails :conversation="selectActiveConversation"/>
        </div>

        <!-- empty state -->
        <div
          v-else
          class="d-flex justify-content-center align-items-center h-100"
        >
          <div class="text-center">
            <i class="fas fa-inbox" style="font-size: 32px"></i>
            <div>No conversation selected</div>
          </div>
        </div>
      </div>
    </Pane>
  </div>
</template>

<script>
import { ChevronLeftIcon } from 'vue-feather-icons'
import Pane from '../Components/Pane'
import PaneHeader from '../Components/PaneHeader'
import CustomerDetails from '../Components/CustomerDetails'
import { createNamespacedHelpers } from 'vuex'

const ConversationModuleV2 = createNamespacedHelpers('conversationV2')

export default {
  name: 'DetailsList',
  props: {
    showDrawerClose: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Pane,
    PaneHeader,
    ChevronLeftIcon,
    CustomerDetails,
  },
  data: () => ({
    innerPaneHeight: 0,
    resizeObserver: null,
  }),
  async mounted() {
    await this.setInnerPaneHeight()
    window.addEventListener('resize', this.setInnerPaneHeight)
    this.resizeObserver = new ResizeObserver(this.setInnerPaneHeight)
    this.resizeObserver.observe(document.querySelector('body'))
  },
  computed: {
    ...ConversationModuleV2.mapGetters(['selectActiveConversation']),
  },
  methods: {
    async setInnerPaneHeight() {
      this.innerPaneHeight =
        document.querySelector('body').clientHeight - $('#timelineHeader')[0]?.offsetHeight || 0
    },
  },
}
</script>
