<template>
  <div class="card py-3 px-4 mb-0 convo-card" :class="{ active: selected }" @click="selectCard">
    <!-- name & time -->
    <div class="d-flex row align-items-center justify-content-between">
      <div style="font-size: 14px; font-weight: 500" class="d-flex align-items-center">
        <i class="fas fa-circle fa-xs text-primary mr-2" v-if="!conversation.opened.length"></i>
        <span v-html="name" v-if="conversation.customer && conversation.customer.highlights" />
        <span v-else>{{ name }}</span>
        <span v-b-popover.hover.right="`${resolvedInfo}`">
          <i class="fas fa-check ml-3" style="color: #00d97e" v-if="conversation.resolved"></i>
        </span>
      </div>
      <div class="text-muted" style="font-size: 13px">
        <time>{{ formattedDate }}</time>
      </div>
    </div>

    <!-- message and rating -->
    <div class="d-flex row align-items-center justify-content-between">
      <div class="my-2 w-75" style="min-height: 45px; font-size: 13px">
        <div v-if="conversation.matchedMessage || conversation.matchedSurvey">
          <span v-if="showMessage">
            <MessageImage
              v-if="matchedMessageMedia.length > 0"
              class="mb-2 col-3"
              :s3source="matchedMessageMedia[0]"
              :alt="`Message image`"
              :toggleDisabled="true"
            />
            <span v-html="matchedMessageBody" v-if="conversation.matchedMessage.highlights"></span>
            <span v-else-if="conversation.lastCommunication">
              {{ lastCommunicationBody | truncate(80) }}
            </span>
            <span v-else>{{ matchedMessageBody | truncate(80) }}</span>
          </span>
          <span v-else>
            <span
              v-html="matchedSurveyFeedback"
              v-if="conversation.matchedSurvey.highlights"
            ></span>
            <span v-else-if="conversation.lastCommunication">{{
              lastCommunicationBody | truncate(80)
            }}</span>
            <span v-else>{{ matchedSurveyFeedback | truncate(80) }}</span>
          </span>
        </div>
        <div v-else-if="conversation.lastCommunication">
          <span v-if="showLastCommunicationMedia">
            <MessageImage
              v-if="lastCommunicationMedia.length > 0"
              class="mb-2 col-3"
              :s3source="lastCommunicationMedia[0]"
              :alt="`Message image`"
              :toggleDisabled="true"
            />
          </span>
          <span v-else>
            {{ lastCommunicationBody | truncate(80) }}
          </span>
        </div>
      </div>
      <div v-if="emojiRating">
        <img
          :src="`/img/ratings/${emojiRating}.png`"
          :alt="`rating ${emojiRating} emoji`"
          class=""
          contain
          height="22px"
        />
      </div>
    </div>

    <!-- location and waiting time -->
    <div class="d-flex row align-items-center justify-content-between">
      <span
        class="badge p-2"
        :class="{ 'active-badge': selected, 'inactive-badge': !selected }"
        style="font-size: 13px"
      >
        {{ location.friendlyName || location.city }}
      </span>
      <span v-if="!selectFilters.search">
        <span v-if="!selectFetchingResponseTimes">
          <span
            :class="{ 'text-warning': responseTime.waiting, 'text-muted': !responseTime.waiting }"
            style="font-size: 13px"
            v-if="responseTime"
          >
            <span v-if="responseTime.responseTime">
              <span class="fe fe-clock"></span>
              <span> {{ responseTime.responseTime }}</span>
            </span>
          </span>
        </span>
        <b-skeleton width="65px" v-else></b-skeleton>
      </span>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/lib/format'
import { createNamespacedHelpers } from 'vuex'
import MessageImage from '@/components/Modules/Message/MessageImageV2'
import { checkName } from '@/lib/name'

const DataCoreModule = createNamespacedHelpers('dataCore')
const ConversationModuleV2 = createNamespacedHelpers('conversationV2')

export default {
  name: 'ConversationCard',
  props: {
    conversation: {
      type: Object,
      required: true,
    },
  },
  components: {
    MessageImage,
  },
  data: () => ({
    formattedDate: null,
  }),
  async mounted() {
    const evaluationDate =
      this.conversation?.matchedMessage?.timelineEventDate ||
      this.conversation?.matchedSurvey?.timelineEventDate ||
      this.conversation?.lastCommunication?.date
    this.formattedDate = evaluationDate ? formatDate(evaluationDate) : null
  },
  computed: {
    ...DataCoreModule.mapGetters(['selectLocationById', 'selectUserById']),
    ...ConversationModuleV2.mapGetters([
      'selectFilters',
      'selectResponseTimeById',
      'selectActiveConversation',
      'selectFetchingResponseTimes',
    ]),
    emojiRating() {
      const conversationToUse = this.conversation.matchedSurvey
        ? this.conversation.matchedSurvey
        : this.conversation.lastSurvey

      return conversationToUse ? conversationToUse.rating : null
    },
    location() {
      return (
        this.selectLocationById(this.conversation.location._id || this.conversation.location) || {}
      )
    },
    responseTime() {
      return this.selectResponseTimeById(this.conversation._id)
    },
    selected() {
      return this.selectActiveConversation?._id === this.conversation._id
    },
    isWaiting() {
      return this.responseTime?.waiting || false
    },
    name() {
      if (this.conversation.customer?.highlights) {
        return this.replaceHighlights(
          this.conversation.customer.name,
          this.conversation.customer.highlights
        )
      }
      return checkName(this.conversation.customer?.name)
    },
    lastCommunicationBody() {
      return this.conversation.lastCommunication?.body
    },
    matchedMessageMedia() {
      let mediaToReturn = []

      if (
        this.conversation.matchedMessage != null &&
        this.conversation.matchedMessage.media != null &&
        this.conversation.matchedMessage.media.length > 0
      ) {
        for (var media of this.conversation.matchedMessage.media) {
          if (!media.key.endsWith('.smil')) {
            mediaToReturn.push(media)
          }
        }
      }

      return mediaToReturn
    },
    lastCommunicationMedia() {
      let mediaToReturn = []

      if (
        this.conversation.lastCommunication != null &&
        this.conversation.lastCommunication.media != null &&
        this.conversation.lastCommunication.media.length > 0
      ) {
        for (var media of this.conversation.lastCommunication.media) {
          if (!media.key.endsWith('.smil')) {
            mediaToReturn.push(media)
          }
        }
      }

      return mediaToReturn
    },
    matchedMessageBody() {
      return this.conversation.matchedMessage
        ? this.replaceHighlights(
            this.conversation.matchedMessage.body,
            this.conversation.matchedMessage.highlights
          )
        : ''
    },
    matchedSurveyFeedback() {
      return this.conversation.matchedSurvey
        ? this.replaceHighlights(
            this.conversation.matchedSurvey.feedback,
            this.conversation.matchedSurvey.highlights
          )
        : ''
    },
    showMessage() {
      if (!this.conversation.matchedMessage && !this.conversation.matchedSurvey) {
        return false
      } else if (
        !this.conversation.matchedMessage ||
        (this.conversation.matchedSurvey && this.conversation.matchedSurvey.score)
      ) {
        return false
      } else if (
        !this.conversation.matchedSurvey ||
        (this.conversation.matchedMessage && this.conversation.matchedMessage.score)
      ) {
        return true
      } else {
        return (
          new Date(this.conversation.matchedMessage?.created).getTime() >
          new Date(this.conversation.matchedSurvey?.updated).getTime()
        )
      }
    },
    showLastCommunicationMedia() {
      const lc = this.conversation.lastCommunication
      if (lc && lc.media?.length) {
        return true
      } else {
        return false
      }
    },
    resolvedInfo() {
      if (this.conversation.autoResolved) {
        return `Resolved automatically by Ovation`
      } else if (this.conversation.resolvedBy) {
        const populatedUser = this.selectUserById(this.conversation.resolvedBy)
        // sometimes the user has been deleted so we need to account for that possibility
        return `Resolved ${
          populatedUser?.firstName
            ? `by ${populatedUser?.firstName || ''} ${populatedUser?.lastName || ''}`
            : ''
        }`
      }
      return null
    },
  },
  methods: {
    ...ConversationModuleV2.mapActions(['selectMostRecentConversationDate']),
    selectCard() {
      this.$emit('select', this.conversation)
    },
    replaceHighlights(body, highlights) {
      if (highlights && highlights.length) {
        highlights.forEach((highlight) => {
          let replacements = highlight.texts
            .map((text) => {
              if (text.type === 'hit') {
                return '<mark>' + text.value + '</mark>'
              } else {
                return text.value
              }
            })
            .join('')

          let originals = highlight.texts
            .map((text) => {
              return text.value
            })
            .join('')

          body = body.replace(originals, replacements)
        })
      }
      return body
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep mark {
  color: #4184f4;
  background: #edf2f9;
}
.convo-card {
  box-shadow: none;
  background: #f9fbfd;
  border: none;
}
.active {
  background: #edf2f9 !important;
}
.active-badge {
  background: #f9fbfd !important;
}
.inactive-badge {
  background: #edf2f9 !important;
}
.card:hover {
  cursor: pointer;
  background: #edf2f9 !important;
}
</style>
