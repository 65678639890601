<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10">
        <div class="header">
          <div class="header-body">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="header-pretitle">Take Action</h6>
                <h1 class="header-title">Winback</h1>
              </div>
              <div class="col-auto">
                <a
                  class="btn btn-outline-secondary mr-2"
                  href="#"
                  @click="openFilters = !openFilters"
                >
                  <span class="fe fe-filter"></span> Filter
                </a>
                <router-link :to="{ name: 'templates' }" class="btn btn-outline-secondary">
                  <span class="fe fe-zap"></span> Templates
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-10">
        <TimelineModal
          @resolve="resolveConversation($event._id)"
          @unresolve="unresolveConversation($event._id)"
          @block="blockCustomer({ companyId: $event.company, customerId: $event.customer._id })"
          @unblock="unblockCustomer({ companyId: $event.company, customerId: $event.customer._id })"
        />

        <transition name="custom-classes-transition" enter-active-class="animated slideInUp faster">
          <FilterGroup :openFilters="openFilters" />
        </transition>

        <!-- Offstate -->
        <transition name="custom-classes-transition" enter-active-class="animated zoomIn faster">
          <Offstate
            v-if="!selectUnresolvedConversations.length"
            :hasConversations="selectConversations.length"
            :hasSearch="!!selectFilters.search"
          />
        </transition>

        <div
          v-if="
            !connectionReady ||
            (selectIsLoading(['FETCH_CONVERSATIONS']) && !selectConversations.length)
          "
          style="min-height: 60px"
          class="container d-flex justify-content-center"
        >
          <b-spinner large variant="primary" />
        </div>
        <div v-else>
          <!-- Unresolved conversations -->
          <transition
            name="custom-classes-transition"
            enter-active-class="animated slideInUp faster"
          >
            <div v-if="selectUnresolvedConversations.length">
              <h2 class="mb-4">
                Conversations
                <span class="badge badge-soft-primary ml-1 mt--1">{{
                  selectUnresolvedConversations.length
                }}</span>
              </h2>
              <div v-for="conversation in selectUnresolvedConversations" :key="conversation.uuid">
                <transition
                  name="custom-classes-transition"
                  enter-active-class="animated slideInUp faster"
                  leave-active-class="animated slideOutDown faster"
                >
                  <ConversationCard
                    :conversation="conversation"
                    @activate="setURL(conversation)"
                    :id="`conversation-${conversation.uuid}`"
                  />
                </transition>
              </div>
            </div>
          </transition>

          <!-- Resolved conversations -->
          <transition
            name="custom-classes-transition"
            enter-active-class="animated slideInUp faster"
          >
            <div v-if="selectResolvedConversations.length">
              <hr class="mb-5 mt-5" />
              <h2 class="mb-4">Resolved</h2>
              <div
                v-for="conversation in selectResolvedConversations"
                :key="conversation.uuid"
                class="resolved"
              >
                <transition
                  name="custom-classes-transition"
                  enter-active-class="animated slideInUp faster"
                  leave-active-class="animated slideOutDown faster"
                >
                  <ConversationCard
                    :conversation="conversation"
                    @activate="setURL(conversation)"
                    :id="`conversation-${conversation.uuid}`"
                  />
                </transition>
              </div>
            </div>
          </transition>
          <div
            v-if="selectIsLoading(['FETCH_CONVERSATIONS']) && this.selectConversations.length"
            class="container d-flex justify-content-center align-items-center"
          >
            <b-spinner small variant="primary" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import FilterGroup from '@/components/Modules/WinbackV2/FilterGroup'
import ConversationCard from '@/components/Modules/WinbackV2/ConversationCard'
import Offstate from '@/components/Modules/WinbackV2/Offstate'
import TimelineModal from '@/components/Modules/WinbackV2/TimelineModal'
import { createNamespacedHelpers, mapGetters } from 'vuex'

const WinbackModule = createNamespacedHelpers('winback')
const WebsocketModule = createNamespacedHelpers('websocket')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'WinbackV2Home',
  components: {
    MainContent,
    FilterGroup,
    ConversationCard,
    Offstate,
    TimelineModal,
  },
  data: () => ({
    openFilters: false,
  }),
  async mounted() {
    const { company: companyId, customer: customerId } = this.$route.query
    try {
      if (companyId && companyId !== this.activeCompany?._id) {
        const company = this.selectCompany(companyId)
        if (company) {
          await this.setActiveCompany(company)
        } else {
          throw new Error('No matching company for winback')
        }
      }
      if (customerId) {
        this.initializeTimeline({ customer: { _id: customerId } })
      }
    } catch (error) {
      this.$notify({ type: 'error', text: error.message })
    }
  },
  computed: {
    ...mapGetters(['selectIsLoading']),
    ...WebsocketModule.mapGetters(['connectionReady']),
    ...WinbackModule.mapGetters([
      'selectConversations',
      'selectConversationCount',
      'selectResolvedConversations',
      'selectUnresolvedConversations',
      'selectFilters',
    ]),
    ...CompanyModule.mapGetters(['activeCompany', 'selectCompany']),
  },
  methods: {
    ...WinbackModule.mapActions([
      'setActiveConversation',
      'blockCustomer',
      'unblockCustomer',
      'resolveConversation',
      'unresolveConversation',
    ]),
    ...CompanyModule.mapActions(['setActiveCompany']),
    async setURL(conversation) {
      window.history.replaceState(
        {},
        null,
        this.$route.path + `?customer=${conversation.customer._id}`
      )
      await this.initializeTimeline(conversation)
    },
    async initializeTimeline(conversation) {
      this.$bvModal.show('winbackTimelineModal')
      await this.setActiveConversation(conversation)
    },
  },
}
</script>

<style scoped lang="scss"></style>
