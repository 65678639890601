<template>
  <div>
    <div class="card" v-if="openFilters">
      <div class="card-header">
        <div class="row">
          <div class="col-12 mb-0">
            <div class="mb-3 clearfix">
              <div class="dropdown d-inline-block mr-4 mb-2 float-left">
                <h6 class="header-pretitle mt-3">Date</h6>
                <DateFilter @update="dateUpdate" />
              </div>
              <div
                v-if="selectActiveCompanies.length > 1"
                class="dropdown d-inline-block float-left mr-4 mb-2"
              >
                <h6 class="header-pretitle mt-3">Company</h6>
                <CompanyFilter
                  @update="companyUpdate"
                  :wideMode="true"
                  :companies="selectActiveCompanies"
                />
              </div>
              <div
                v-if="selectActiveLocations.length > 1"
                class="dropdown d-inline-block float-left mr-4 mb-2"
              >
                <h6 class="header-pretitle mt-3">Location</h6>
                <LocationFilter
                  @update="locationUpdate"
                  :selectedCompanies="filteredCompanies"
                  :locations="selectActiveLocations"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SearchFilter v-model="search" @input="formatAndApplyFilters"></SearchFilter>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import LocationFilter from '@/components/Modules/Filters/LocationFilter'
import DateFilter from '@/components/Modules/Filters/DateFilter'
import CompanyFilter from '@/components/Modules/Filters/CompanyFilter'
import SearchFilter from '@/components/Modules/Filters/SearchFilter'

const DataCoreModule = createNamespacedHelpers('dataCore')
const WinbackModule = createNamespacedHelpers('winback')

export default {
  name: 'FilterGroup',
  props: ['openFilters'],
  components: {
    LocationFilter,
    DateFilter,
    CompanyFilter,
    SearchFilter,
  },
  data: () => ({
    filteredCompanies: [],
    filteredLocations: [],
    filteredDate: null,
    search: null,
  }),
  computed: {
    ...DataCoreModule.mapGetters([
      'selectActiveLocations',
      'selectActiveCompanies',
      'selectActiveCompanyIds',
    ]),
  },
  watch: {
    selectActiveCompanyIds() {
      this.filteredCompanies = []
      this.filteredLocations = []
      this.filteredDate = null
      this.search = null
    },
  },
  methods: {
    ...WinbackModule.mapActions(['applyFilters']),
    dateUpdate(val) {
      this.filteredDate = val
      this.formatAndApplyFilters()
    },
    companyUpdate(val) {
      console.log(val)
      this.filteredCompanies = val
      this.formatAndApplyFilters()
    },
    locationUpdate(val) {
      this.filteredLocations = val
      this.formatAndApplyFilters()
    },
    formatAndApplyFilters() {
      this.applyFilters({
        companies:
          this.filteredCompanies && this.filteredCompanies.length ? this.filteredCompanies : null,
        locations:
          this.filteredLocations && this.filteredLocations.length ? this.filteredLocations : null,
        date: this.filteredDate && Object.keys(this.filteredDate).length ? this.filteredDate : null,
        search: this.search,
      })
    },
  },
}
</script>
