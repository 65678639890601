<template>
  <MainContent>
    <div v-if="activeCompany" :key="activeCompany._id">
      <WinbackView v-if="activeCompany._id !== 'ALL_COMPANIES'" />
      <div v-else>
        <WinbackV2 @scroll="onScroll" v-if="connectionReady" />
      </div>
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import WinbackView from '@/components/Modules/Winback/WinbackView.vue'
import WinbackV2 from '@/components/Modules/WinbackV2/Home'
import { trackEvent } from '@/lib/analytics'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { throttle } from 'lodash-es'

const DataCoreModule = createNamespacedHelpers('dataCore')
const WinbackModule = createNamespacedHelpers('winback')
const WebsocketModule = createNamespacedHelpers('websocket')
const WebsocketModuleV2 = createNamespacedHelpers('websocketV2')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')
const CompanyModule = createNamespacedHelpers('company')

const fetchMoreConversations = throttle((self) => {
  self.fetchConversations()
}, 1000)

export default {
  name: 'WinbackPage',
  components: {
    MainContent,
    WinbackV2,
    WinbackView,
  },
  mounted() {
    trackEvent(this.$intercom, 'Winback')
    window.addEventListener('scroll', this.onScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true)
  },
  computed: {
    ...DataCoreModule.mapGetters(['selectActiveCompanyIds']),
    ...WinbackModule.mapGetters(['selectFilters']),
    ...mapGetters(['selectIsLoading']),
    ...WebsocketModule.mapGetters(['connectionReady']),
    ...WebsocketModuleV2.mapGetters(['selectPusher']),
    ...FeatureFlagModule.mapGetters(['isEnabled']),
    ...CompanyModule.mapGetters(['activeCompany']),
  },
  methods: {
    ...WinbackModule.mapActions(['fetchConversations']),
    onScroll() {
      if (
        this.activeCompany?._id === 'ALL_COMPANIES' &&
        window.innerHeight + window.scrollY === document.body.offsetHeight
      ) {
        if (!this.selectIsLoading(['FETCH_CONVERSATIONS']) && !this.selectFilters.search) {
          fetchMoreConversations(this)
        }
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
