<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-auto pr-0">
          <span class="fas fa-spinner fa-spin text-muted" v-if="isSearching"></span>
          <span class="fe fe-search text-muted" v-else></span>
        </div>
        <div class="col">
          <input
            type="search"
            class="form-control form-control-flush search"
            placeholder="Search"
            v-model="search"
            :disabled="isSearching"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { mapGetters } from 'vuex'

export default {
  name: 'SearchFilter',
  props: ['value'],
  data: () => ({
    search: null,
  }),
  computed: {
    ...mapGetters(['selectIsLoading']),
    isSearching() {
      return this.selectIsLoading(['APPLY_FILTERS'])
    },
  },
  watch: {
    search: debounce(function () {
      this.$emit('input', this.search === '' ? null : this.search)
    }, 500),
    value(val) {
      this.search = val
    },
  },
}
</script>
