<template>
  <div class="p-3">
    <div v-if="!loading">
      <!-- phone -->
      <div class="row flex-nowrap" v-if="formattedPhone">
        <span class="metric col-6">Phone</span>
        <span
          v-clipboard:copy="selectMetrics.phone"
          v-clipboard:success="onCopyToClipboard"
          style="cursor: pointer"
        >
          {{ formattedPhone }}
        </span>
      </div>
      <!-- email -->
      <div class="row flex-nowrap">
        <span class="metric col-6">Email</span>
        <span
          v-clipboard:copy="selectMetrics.email"
          v-clipboard:success="onCopyToClipboard"
          class="truncate"
          style="cursor: pointer"
          v-b-tooltip.hover="selectMetrics.email"
        >
          {{ selectMetrics.email }}
        </span>
      </div>
      <!-- marketing Opt-in -->
      <div class="row flex-nowrap">
        <span class="metric col-6">Marketing Opt-in</span>
        <span class="text-capitalize">{{ selectMetrics.marketingOptIn }}</span>
      </div>
      <!-- frequency -->
      <div class="row flex-nowrap" v-if="selectMetrics.frequency">
        <span class="metric col-6">Frequency</span>
        <span v-if="selectMetrics.frequency"> Every {{ selectMetrics.frequency }} days </span>
      </div>
      <!-- Last visit -->
      <div class="row flex-nowrap" v-if="lastVisit">
        <span class="metric col-6">Last Visit</span>
        <span>{{ lastVisit }}</span>
      </div>
      <!-- Avg spend -->
      <div
        class="row flex-nowrap"
        v-if="selectMetrics.averageSpend || selectMetrics.averageSpend === 0"
      >
        <span class="metric col-6">Avg Spend</span>
        <span>${{ selectMetrics.averageSpend }}</span>
      </div>
      <!-- lifetime spend -->
      <div
        class="row flex-nowrap"
        v-if="selectMetrics.totalSpend || selectMetrics.totalSpend === 0"
      >
        <span class="metric col-6">
          Total Spend
          <i
            v-b-tooltip.hover
            :title="`Total amount spent through Ovation integrations since ${$moment(
              selectMetrics.firstOrderDate
            ).format('L')}`"
            class="fas fa-info-circle ml-1 text-muted"
          ></i>
        </span>
        <span>${{ selectMetrics.totalSpend }}</span>
      </div>
      <!-- locations -->
      <div class="row flex-nowrap" v-if="selectMetrics.locations && selectMetrics.locations.length">
        <span class="metric col-6">Locations</span>
        <span>
          <div v-for="location in selectMetrics.locations" :key="location._id">
            <span class="badge p-2 mb-1" style="font-size: 13px; background: #edf2f9">
              {{ location.friendlyName || location.city }}
            </span>
          </div>
        </span>
      </div>
      <!-- average satifaction -->
      <div class="row flex-nowrap" v-if="selectMetrics.averageSatisfaction">
        <span class="metric col-6">Avg Satisfaction</span>
        <span>
          <img
            :src="`/img/ratings/${Math.round(selectMetrics.averageSatisfaction)}.png`"
            :alt="`rating ${Math.round(selectMetrics.averageSatisfaction)} emoji`"
            class="mt--1"
            contain
            height="22px"
          />
        </span>
        <span class="ml-2" v-if="selectMetrics.averageSatisfaction">
          ({{ averageSatisfaction }})
        </span>
      </div>
    </div>

    <div v-else>
      <div class="mb-2" v-for="x in 9" :key="x">
        <b-skeleton width="100%" height="24px" />
      </div>
    </div>
  </div>
</template>

<script>
import { formatPhone } from '@/lib/format'
import { createNamespacedHelpers } from 'vuex'

const CustomerModule = createNamespacedHelpers('customer')

export default {
  name: 'CustomerDetails',
  props: {
    conversation: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: true,
  }),
  async mounted() {
    const { customer, company } = this.conversation
    await this.fetchMetrics({ customerId: customer._id, companyId: company })
    this.loading = false
  },
  computed: {
    ...CustomerModule.mapGetters(['selectMetrics']),
    lastVisit() {
      const visitDate = this.selectMetrics?.lastVisit?.date
      if (!visitDate) return null
      const days = this.$moment().diff(visitDate, 'days')
      return days < 14
        ? `${days} days ago`
        : days < 60
        ? `${Math.round(days / 7)} weeks ago`
        : `${Math.round(days / 30)} months ago`
    },
    averageSatisfaction() {
      return parseFloat(this.selectMetrics.averageSatisfaction).toFixed(1)
    },
    formattedPhone() {
      return formatPhone(this.selectMetrics.phone) || null
    },
  },
  methods: {
    ...CustomerModule.mapActions(['fetchMetrics']),
    onCopyToClipboard() {
      this.$notify({
        title: 'Copied to clipboard!',
        duration: 50,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.metric {
  color: #95aac9;
  white-space: nowrap !important;
  min-width: 140px;
}
.row {
  margin-bottom: 8px;
}
span {
  font-size: 14px;
}
.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
