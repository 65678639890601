<template>
  <div class="card" :id="`conversation-${conversation._id}`">
    <div class="card-body p-0">
      <!-- Header -->
      <div>
        <div class="row">
          <div class="company-logo" v-if="selectActiveCompanyIds.length > 1">
            <div class="avatar-xs" v-if="companyLogo">
              <img :src="companyLogo" class="avatar-img rounded-circle" />
            </div>
          </div>
          <div class="col-auto border-right text-center d-none d-sm-block">
            <!-- <div v-if="true" class="container d-flex "> -->
            <div
              v-if="
                selectIsLoading([
                  `RESOLVE_CONVERSATION-${conversation._id}`,
                  `UNRESOLVE_CONVERSATION-${conversation._id}`,
                ])
              "
              class="container d-flex justify-content-center"
            >
              <b-spinner variant="primary" class="conversation-spinner" />
            </div>
            <a
              v-else
              @click="
                resolved
                  ? unresolveConversation(conversation._id)
                  : resolveConversation(conversation._id)
              "
              href="#!"
              :id="conversation._id"
              v-b-tooltip="{
                title: resolved ? 'Unresolve' : 'Ready to move this one into the done pile?',
                placement: 'bottom',
                trigger: 'focus',
                variant: 'primary',
              }"
              class="btn btn btn-rounded-circle checklist-button"
              :class="{ 'btn-success': resolved, 'btn-white': !resolved }"
            >
              <span class="fe fe-check"></span>
            </a>
          </div>
          <div class="col m-4 hover-card" @click="$emit('activate', conversation)">
            <div class="row align-items-center">
              <div class="col ml-0 ml-sm--3">
                <!-- Title -->
                <h3
                  class="card-title text-capitalize"
                  :class="{ 'font-weight-light': opened || resolved }"
                >
                  <span class="mr-1">{{ emoji() }}</span>
                  <span v-html="name" v-if="conversation.customer.highlights" />
                  <span v-else>{{ name }}</span>
                </h3>

                <!-- Last message -->
                <p
                  :class="{ 'text-muted': opened }"
                  class="text-small mt-1 mb-3"
                  v-if="conversation.lastMessage || conversation.lastSurvey"
                >
                  <span v-if="showMessage">
                    <MessageImage
                      v-if="lastMessageMedia.length > 0"
                      class="mb-2 col-3"
                      :s3source="lastMessageMedia[0]"
                      :alt="`Message image`"
                      :toggleDisabled="true"
                    />
                    <span
                      v-html="lastMessageBody"
                      v-if="conversation.lastMessage.highlights"
                    ></span>
                    <span v-else>{{ lastMessageBody | truncate(50) }}</span>
                  </span>
                  <span v-else>
                    <span
                      v-html="lastSurveyFeedback"
                      v-if="conversation.lastSurvey.highlights"
                    ></span>
                    <span v-else>{{ lastSurveyFeedback | truncate(50) }}</span>
                  </span>
                </p>
                <!-- Time -->
                <p class="card-text mb--2 mt-0">
                  <span class="badge badge-soft-outline mr-2 mb-1">
                    <span class="fe fe-map-pin"></span>
                    &nbsp;{{ location.friendlyName || location.city }}
                  </span>
                  <span
                    class="badge badge-soft-outline mr-2 mb-1"
                    v-if="conversation && (conversation.lastMessage || conversation.lastSurvey)"
                  >
                    <span class="fe fe-clock"></span>&nbsp;
                    <time>{{
                      (conversation.lastMessage
                        ? conversation.lastMessage.created
                        : conversation.lastSurvey.updated) | moment('from', 'now')
                    }}</time>
                  </span>
                  <span
                    class="badge badge-soft-outline mr-2 mb-1"
                    v-if="responseTime"
                    v-b-popover.hover.bottom="resTimeMsg"
                  >
                    <span
                      class="fas fa-hourglass-half text-warning"
                      v-if="responseTime.waiting == true"
                    ></span>
                    <span class="far fa-check-circle text-success" v-else></span>
                    <span> {{ responseTime.responseTime }}</span>
                  </span>
                </p>
              </div>

              <!-- Show timeline button -->
              <div class="col-auto">
                <a
                  href="javascript:;"
                  @click="$emit('activate', conversation)"
                  class="btn btn-large btn-link arrow-button"
                >
                  <span v-if="!opened && !resolved" class="text-primary">●</span>
                  <span v-else class="fe fe-chevron-right"></span>
                </a>
              </div>
            </div>
            <!-- / .row -->
          </div>
        </div>
      </div>
    </div>

    <!-- Response record -->
    <div class="card-footer" v-if="resolvedBy">
      <div class="row">
        <div class="col-auto">
          <Avatar
            :img="resolvedBy.avatar"
            :first-name="resolvedBy.firstName"
            :last-name="resolvedBy.lastName"
            size="xs"
            status="online"
          />
        </div>
        <div class="col ml--3 mt-2">
          <h5 class="card-title mb-2 text-muted">
            {{ conversation.autoResolved ? 'Resolved automatically' : 'Resolved' }}
            <span v-if="resolvedBy.firstName"
              >by {{ resolvedBy.firstName }} {{ resolvedBy.lastName }}</span
            >
            <span v-if="!resolvedBy.firstName">.</span>
          </h5>
        </div>
        <div class="col-auto">
          <p class="card-text small text-muted mt-1">
            <time>{{
              conversation && conversation.lastMessage && conversation.lastMessage.score
                ? conversation.lastMessage.created
                : conversation.updated | moment('MMM. Do YYYY, h:mma')
            }}</time>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRating } from '@/lib/rating'
import { firstName } from '@/lib/name'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import Avatar from '@/components/MainContent/Avatar'
import MessageImage from '@/components/Modules/Message/MessageImage'
import Config from '@/config'

const WinbackModule = createNamespacedHelpers('winback')
const DataCoreModule = createNamespacedHelpers('dataCore')

export default {
  name: 'ConversationCard',
  props: ['conversation'],
  data: () => ({
    resTimeMsg: 'The amount of time the customer is/was waiting for a response',
  }),
  components: {
    Avatar,
    MessageImage,
  },
  computed: {
    ...mapGetters(['selectIsLoading']),
    ...DataCoreModule.mapGetters([
      'selectCompanyById',
      'selectLocationById',
      'selectActiveUserId',
      'selectUserById',
      'selectActiveCompanyIds',
    ]),
    ...WinbackModule.mapGetters(['selectResponseTimeById']),
    company() {
      return this.selectCompanyById(this.conversation.company)
    },
    location() {
      return this.selectLocationById(this.conversation.location) || {}
    },
    responseTime() {
      return this.selectResponseTimeById(this.conversation._id)
    },
    companyLogo() {
      return this.company && this.company.settings && this.company.settings.logo
        ? this.company.settings.logo
        : null
    },
    name() {
      if (this.conversation.customer.highlights) {
        return this.replaceHighlights(
          this.conversation.customer.name,
          this.conversation.customer.highlights
        )
      }
      return firstName(this.conversation.customer.name)
    },
    resolved() {
      return this.conversation.resolved === true ? true : false
    },
    opened() {
      return this.conversation.opened && this.conversation.opened.includes(this.selectActiveUserId)
        ? true
        : false
    },
    resolvedBy() {
      if (!this.conversation.resolved) return null

      if (this.conversation.autoResolved) {
        return {
          avatar: Config.icons.ovation,
          firstName: 'Ovation',
          lastName: '',
        }
      } else if (this.conversation.resolvedBy) {
        return this.selectUserById(this.conversation.resolvedBy)
      }

      return null
    },
    lastMessageMedia() {
      let mediaToReturn = []

      if (
        this.conversation.lastMessage != null &&
        this.conversation.lastMessage.media != null &&
        this.conversation.lastMessage.media.length > 0
      ) {
        for (var media of this.conversation.lastMessage.media) {
          if (!media.key.endsWith('.smil')) {
            mediaToReturn.push(media)
          }
        }
      }

      return mediaToReturn
    },
    lastMessageBody() {
      return this.conversation.lastMessage
        ? this.replaceHighlights(
            this.conversation.lastMessage.body,
            this.conversation.lastMessage.highlights
          )
        : ''
    },
    lastSurveyFeedback() {
      return this.conversation.lastSurvey
        ? this.replaceHighlights(
            this.conversation.lastSurvey.feedback,
            this.conversation.lastSurvey.highlights
          )
        : ''
    },
    showMessage() {
      if (!this.conversation.lastMessage && !this.conversation.lastSurvey) {
        return false
      } else if (
        !this.conversation.lastMessage ||
        (this.conversation.lastSurvey && this.conversation.lastSurvey.score)
      ) {
        return false
      } else if (
        !this.conversation.lastSurvey ||
        (this.conversation.lastMessage && this.conversation.lastMessage.score)
      ) {
        return true
      } else {
        return (
          new Date(this.conversation.lastMessage.created).getTime() >
          new Date(this.conversation.lastSurvey.updated).getTime()
        )
      }
    },
  },
  methods: {
    ...WinbackModule.mapActions(['resolveConversation', 'unresolveConversation']),
    replaceHighlights(body, highlights) {
      if (highlights && highlights.length) {
        highlights.forEach((highlight) => {
          let replacements = highlight.texts
            .map((text) => {
              if (text.type === 'hit') {
                return '<mark>' + text.value + '</mark>'
              } else {
                return text.value
              }
            })
            .join('')

          let originals = highlight.texts
            .map((text) => {
              return text.value
            })
            .join('')

          body = body.replace(originals, replacements)
        })
      }
      return body
    },
    emoji() {
      let rating = 3
      if (this.conversation.lastSurvey) {
        rating = this.conversation.lastSurvey.rating
      } else if (this.name === 'Anonymous') {
        return
      }
      let parsedRating = getRating(rating)
      return parsedRating ? parsedRating.emoti : null
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep mark {
  color: #ef6c00;
}

.company-logo {
  .avatar-xs {
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px;
  }
}

.border-right {
  border-color: #f1f1f1 !important;
}
.card-inactive {
  .border-right {
    border-color: #f1f1f1 !important;
  }
  .comment-body {
    border: 1px solid #f1f1f1;
  }
}
.comment-text {
  font-size: 16px;
}
.comment-title {
  font-size: 16px;
}
.emoji {
  position: absolute;
  bottom: -9px;
  right: 10px;
  font-size: 23px;
}
.checklist-button {
  transition: all 0.3s ease;
  margin: 15px;
  display: inline-block;
  position: relative;
  left: 7px;
  top: 7px;
  width: 35px;
  height: 35px;
  span {
    transition: all 0.3s ease;
    position: relative;
    bottom: 4px;
    font-size: 18px;
    opacity: 0;
  }
  &.btn-success {
    opacity: 0.8;
    span {
      opacity: 1;
      color: #fff;
      display: block;
    }
  }
  &:hover {
    span {
      // color: #00d97e;
      opacity: 0.5;
    }
  }
}
.arrow-button {
  font-size: 22px;
  color: #bbb;
}
.hover-card {
  &:hover {
    cursor: pointer;
  }
}
.conversation-spinner {
  margin-left: 9px;
  margin-top: 22px;
}
</style>
