<template>
  <div class="card col-12" v-if="show">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/happiness.svg"
            alt="..."
            class="img-fluid mt--5 mb-4"
            style="max-width: 280px"
            v-if="!hasSearch"
          />

          <div v-if="!hasSearch">
            <h2 v-if="!hasConversations">Take them from 🤬 to 😍 with Winback™</h2>
            <h2 v-else>Nice work winning 'em back 🥇</h2>

            <p class="text-muted">
              When a customer has a bad experience, this is where you come to win them back.
            </p>
          </div>
          <div v-else>
            <h2>🙁 Sorry, we couldn't find any results</h2>
            <p class="text-muted">Please try again using different words.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Offstate',
  props: ['hasConversations', 'hasSearch'],
  computed: {
    ...mapGetters(['selectIsLoading']),
    show() {
      return !this.selectIsLoading(['FETCH_CONVERSATIONS']) &&
        (!this.hasSearch || !this.hasConversations)
        ? true
        : false
    },
  },
}
</script>

<style scoped lang="scss"></style>
