<template>
  <div>
    <div v-if="initialized">
      <!-- this is the 3 pane controller view  -->
      <div class="row mx-0 flex-nowrap" v-if="!selectIsMobileView">
        <!-- conversations list -->
        <div class="col-3 px-0" style="min-width: 395px">
          <ConversationList
            @filtersUpdated="refreshLists('standardView')"
            @fetchMore="fetchMoreConversations('standardView')"
            :queryPending="queryPending"
            :fetchMorePending="fetchMorePending"
          />
        </div>

        <!-- messages window -->
        <div class="col px-0" style="min-width: 450px">
          <MessageList @toggleDetails="toggleDetails" />
        </div>

        <!-- toggleable right pane -->
        <div
          class="col-3 px-0"
          v-if="selectShowDetailsPane && !selectIsMobileView"
          style="min-width: 300px"
          :key="selectActiveConversation && selectActiveConversation._id"
        >
          <DetailsList />
        </div>
      </div>

      <!-- this is the mobile web app view, show if screen < 768px -->
      <div v-else>
        <WinbackViewMobile
          :queryPending="queryPending"
          :fetchMorePending="fetchMorePending"
          @filtersUpdated="refreshLists('mobileView')"
          @fetchMore="fetchMoreConversations('mobileView')"
          @toggleDetails="toggleDetails"
        />
      </div>
    </div>

    <TimelineDrawer v-if="selectIsMobileView" ref="timelineDrawer" />
    <DetailsDrawer
      v-if="selectIsMobileView"
      ref="detailsDrawer"
      :key="selectActiveConversation && selectActiveConversation._id"
    />
  </div>
</template>

<script>
import ConversationList from './SubViews/ConversationList'
import TimelineDrawer from './Components/TimelineDrawer'
import DetailsDrawer from './Components/DetailsDrawer'
import MessageList from './SubViews/MessageList'
import DetailsList from './SubViews/DetailsList'
import WinbackViewMobile from './WinbackViewMobile'
import { trackEvent } from '@/lib/analytics'
import { createNamespacedHelpers } from 'vuex'
import { throttle } from 'lodash-es'

const LocationModule = createNamespacedHelpers('location')
const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const WebsocketModuleV2 = createNamespacedHelpers('websocketV2')
const CompanyModule = createNamespacedHelpers('company')
const GroupModule = createNamespacedHelpers('group')
const DataCoreModule = createNamespacedHelpers('dataCore')

const throttledFetchConversations = throttle(async (self, options) => {
  try {
    await self.fetchConversations(options)
  } catch (e) {
    if (self.selectFilters && self.selectFilters.search) {
      self.$notify({
        type: 'error',
        text: 'Sorry that search has too many results to process. Please try a different search.',
      })
    } else {
      self.$notify({
        type: 'error',
        text: 'Sorry something went wrong fetching the conversations.',
      })
    }
  }
}, 1000)

export default {
  name: 'WinbackView',
  components: {
    ConversationList,
    MessageList,
    DetailsList,
    WinbackViewMobile,
    TimelineDrawer,
    DetailsDrawer,
  },
  data: () => ({
    initialized: false,
    queryPending: true,
    fetchMorePending: false,
  }),
  async mounted() {
    await Promise.all([this.resetAllConversationData(), this.winbackCompanyChannelSubscribe()])
    await Promise.all([
      this.getUserLocationsByActiveCompany(),
      throttledFetchConversations(this, { reset: true, trace: 'WinbackView: mounted' }),
      this.fetchGroups({
        memberType: 'location',
        companyIds: this.selectActiveCompanyIds,
      }),
    ])
    await this.checkSmallScreen()
    window.addEventListener('resize', this.checkSmallScreen)
    this.queryPending = false
    this.initialized = true
    await this.parseRouteDetails()
  },
  beforeDestroy() {
    this.winbackCompanyChannelUnsubscribe()
    this.resetAllConversationData()
    window.removeEventListener('resize', this.checkSmallScreen)
  },
  computed: {
    ...DataCoreModule.mapGetters(['selectActiveCompanyIds']),
    ...CompanyModule.mapGetters(['activeCompany', 'selectCompany']),
    ...ConversationModuleV2.mapGetters([
      'selectIsMobileView',
      'selectIsMobileView',
      'selectShowDetailsPane',
      'selectActiveConversation',
      'selectFilters',
    ]),
  },
  methods: {
    ...LocationModule.mapActions(['getUserLocationsByActiveCompany']),
    ...ConversationModuleV2.mapActions([
      'fetchConversations',
      'resetAllConversationData',
      'setActiveConversation',
      'updateIsMobileView',
      'fetchSpecificConversation',
      'updateFilters',
      'updateShowDetailsPane',
      'updateIsDeeplink',
    ]),
    ...WebsocketModuleV2.mapActions([
      'winbackCompanyChannelSubscribe',
      'winbackCompanyChannelUnsubscribe',
    ]),
    ...GroupModule.mapActions(['fetchGroups']),
    ...CompanyModule.mapActions(['setActiveCompany']),
    async checkSmallScreen() {
      if (window.innerWidth < 768 && !this.selectIsMobileView) {
        this.showDetails = false
        this.updateIsMobileView(true)
      } else if (window.innerWidth >= 768 && this.selectIsMobileView) {
        this.updateIsMobileView(false)
      }

      if (window.innerWidth > 1400 && !this.selectShowDetailsPane) {
        this.updateShowDetailsPane(true)
      } else if (window.innerWidth < 1401 && this.selectShowDetailsPane) {
        this.updateShowDetailsPane(false)
      }
    },
    async parseRouteDetails() {
      // this function is only to account for deep linking
      const { conversationId } = this.$route.params
      try {
        if (conversationId) {
          await this.fetchSpecificConversation(conversationId)
          await this.updateIsDeeplink(true)
        } else {
          // backward compatibility
          const { company: companyId, customer: customerId } = this.$route.query
          if (companyId && companyId !== this.activeCompany?._id) {
            const company = this.selectCompany(companyId)
            if (company) {
              await this.setActiveCompany(company)
            } else {
              throw new Error('No matching company for winback')
            }
          }
          if (customerId) {
            this.setActiveConversation({ customer: { _id: customerId } })
            trackEvent(this.$intercom, 'Deeplink')
            if (this.selectIsMobileView) {
              this.$refs.timelineDrawer.open()
            }
          }
        }
      } catch (error) {
        this.$notify({ type: 'error', text: error.message })
      }
    },
    toggleDetails() {
      this.updateShowDetailsPane(!this.selectShowDetailsPane)
      if (this.selectIsMobileView) {
        this.$refs.detailsDrawer.open()
      }
    },
    async refreshLists(view) {
      this.queryPending = true
      await throttledFetchConversations(this, {
        reset: true,
        trace: `WinbackView: refreshLists - ${view}`,
      })
      this.queryPending = false
    },
    async fetchMoreConversations(view) {
      this.fetchMorePending = true
      await throttledFetchConversations(this, {
        reset: false,
        trace: `WinbackView: fetchMoreConversations - ${view}`,
      })
      this.fetchMorePending = false
    },
  },
}
</script>

<style scoped>
.col {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
