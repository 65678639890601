<template>
  <div>
    <ConversationList
      @filtersUpdated="$emit('filtersUpdated')"
      @fetchMore="$emit('fetchMore')"
      :queryPending="queryPending"
      :fetchMorePending="fetchMorePending"
      @toggleDetails="$emit('toggleDetails')"
    />
  </div>
</template>

<script>
import ConversationList from './SubViews/ConversationList'
import { trackEvent } from '@/lib/analytics'
import { createNamespacedHelpers } from 'vuex'

const ConversationModuleV2 = createNamespacedHelpers('conversationV2')

export default {
  name: 'WinbackViewMobile',
  props: {
    queryPending: {
      type: Boolean,
      required: true,
    },
    fetchMorePending: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ConversationList,
  },
  mounted() {
    trackEvent(this.$intercom, 'MobileWebApp')
  },
}
</script>
