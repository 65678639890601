<template>
  <b-sidebar
    id="details-drawer"
    bg-variant="white"
    ref="detailsDrawer"
    right
    no-header
    v-model="visible"
    class="border-left bd-left-gray"
    backdrop
    width="100%"
    style="z-index: 2001"
  >
    <DetailsList v-if="visible" :showDrawerClose="true" @close="close"/>
  </b-sidebar>
</template>

<script>
import DetailsList from '../SubViews/DetailsList'

export default {
  name: 'DetailsDrawer',
  components: {
    DetailsList,
  },
  data: () => ({
    visible: false,
  }),
  methods: {
    open() {
      this.visible = true
    },
    close() {
      this.visible = false
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .b-sidebar {
  border-left: 1.5px solid #d2ddec;
}
</style>
