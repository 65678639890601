<template>
  <div v-if="currentPrefix">
    <popper
      ref="popperRef"
      class="date-popper"
      trigger="click"
      @hide="closePopup"
      :options="{
        placement: 'bottom',
        modifiers: {
          offset: { offset: '0,6px' },
          flip: { enabled: false },
          gpuAcceleration: true,
        },
      }"
    >
      <div class="popper">
        <div class="row mt-0">
          <div class="col">
            <h6 class="header-pretitle mb--4 mt-3 text-left">Select a date filter</h6>
          </div>
          <div class="col text-right">
            <button @click="reset()" class="btn btn-outline-secondary btn-sm mb--2">Reset</button>
          </div>
        </div>
        <hr />
        <div class="input-group">
          <div class="input-group-prepend">
            <!-- Prefix Dropdown Dropdown -->
            <button
              class="btn btn-white dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ currentPrefix.name }}
            </button>
            <div class="dropdown-menu">
              <a
                v-for="prefix in prefixes"
                :key="prefix.type"
                data-toggle="true"
                class="dropdown-item"
                href="javascript:;"
                @click="setPrefix(prefix)"
                >{{ prefix.name }}</a
              >
            </div>
          </div>

          <flat-pickr
            ref="rangeRef"
            v-model="currentPrefix.date"
            v-if="currentPrefix.type === 'range'"
            class="form-control"
            :config="{
              mode: 'range',
              dateFormat: 'M j, Y',
              altFormat: 'M j, Y',
              altInput: true,
              inline: true,
              wrap: false,
            }"
            placeholder="Choose a range"
            name="date"
          ></flat-pickr>

          <input
            v-if="currentPrefix.type === 'ago'"
            type="text"
            class="form-control"
            placeholder="90"
            v-model="currentPrefix.date"
          />
          <div class="input-group-append" v-if="currentPrefix.type === 'ago'">
            <button
              class="btn btn-outline-secondary dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {{ currentPrefix.unit }}
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="javascript:;" @click="setUnit('days')">days</a>
              <a class="dropdown-item" href="javascript:;" @click="setUnit('weeks')">weeks</a>
              <a class="dropdown-item" href="javascript:;" @click="setUnit('months')">months</a>
            </div>
          </div>

          <flat-pickr
            v-if="currentPrefix.type === 'since' || currentPrefix.type === 'on'"
            ref="sinceRef"
            v-model="currentPrefix.date"
            class="form-control"
            :config="{
              mode: 'single',
              dateFormat: 'M j, Y',
              altInput: true,
              inline: true,
              wrap: false,
            }"
            placeholder="Choose a date"
            name="date"
          ></flat-pickr>
        </div>
        <button href="javascript:;" @click="applyFilters" class="btn btn-primary mt-3 w-100">
          Apply
        </button>
      </div>

      <button slot="reference" class="btn btn-outline-secondary" style="font-size: 14px">
        <span class="fe fe-clock"></span>
        {{
          currentPrefix.date
            ? `${currentPrefix.name} ${currentPrefix.date}${
                currentPrefix.unit ? ' ' + currentPrefix.unit : ''
              }`
            : 'All time'
        }}
      </button>
    </popper>
  </div>
</template>

<script>
import Popper from 'vue-popperjs'
import { parseDateSettings } from '@/lib/date'

export default {
  name: 'DateFilter',
  components: {
    popper: Popper,
  },
  data: () => ({
    prefixes: [
      { name: 'All time', type: 'all' },
      { name: 'In the last', type: 'ago', unit: 'days', date: null },
      { name: 'From', type: 'range', date: null },
      { name: 'Since', type: 'since', date: null },
      { name: 'On', type: 'on', date: null },
    ],
    currentPrefix: null,
    lastEmittedPrefix: null,
  }),
  methods: {
    setPrefix(prefix) {
      this.currentPrefix = prefix
    },
    setUnit(unit) {
      this.currentPrefix.unit = unit
    },
    reset() {
      this.setPrefix({ ...this.prefixes[0] })
      this.applyFilters()
      this.closePopup()
    },
    initialize() {
      this.setPrefix({ ...this.prefixes[1] })
    },
    applyFilters() {
      this.lastEmittedPrefix = { ...this.currentPrefix }
      this.$emit('update', parseDateSettings({ ...this.currentPrefix }))
      this.closePopup(true)
    },
    closePopup(emitted) {
      if (emitted !== true) {
        if (this.lastEmittedPrefix) {
          this.setPrefix(this.lastEmittedPrefix)
        } else {
          this.initialize()
        }
      }
      this.$refs.popperRef.doClose()
    },
  },
  mounted() {
    this.initialize()
  },
}
</script>
